

 /* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&family=Noto+Sans+JP:wght@400;500;700&family=Noto+Sans+TC:wght@400;500;700&display=swap');  */
 /* @font-face {
  font-family: PingFang;
   src:url('assets/font/PingFang.ttc');  
}  */

#root{
margin-left: auto;
margin-right: auto;
}


::-webkit-scrollbar{
  width: 0px;
}

body {

  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

background-color: #FAFAFA;
color:#12171E;

 
}

@media screen and (max-width: 767px) {
.line5limit {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}
}

.anim {
  transition: all 0.4s ease-out;
}




.slideshow-container {
  position: relative;
  width: 100%;
  height: 300px; /* 設定輪播容器的高度 */
  overflow: hidden;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0; /* 圖片一開始是透明的，不可見 */
  transition: opacity 1s ease; /* 過渡效果：透明度在1秒內改變 */
}

.slide.active {
  opacity: 1; /* 顯示活動中的圖片，設置不透明度為1 */
}






.visibleAnim{
  overflow: hidden;
  height: 56px;
}
.visibleAnim:before{
  content: '[';
  left: 0;
  line-height: 56px;
}
.visibleAnim:after{
  content: ']';
  right: 0;
  line-height: 56px;
}
.visibleAnim::before, .visibleAnim:after{
  position: absolute;
  top: 0;
  color: #fff;
  font-size: 58px;
  animation: opacity 4s;
  animation-iteration-count: infinite;
}

.visibleAnim ul{
  margin-top: 0;
  text-align: left;
  list-style: none;
  text-transform: capitalize;
  animation: change 12s;
  animation-iteration-count: infinite;
}
.visibleAnim ul li{
  height:80px;
  line-height: 56px;
}
@keyframes opacity {
  0%, 100%{
    opacity: 0;
  }
  50%{
    opacity: 1;
  }
}
@keyframes change {
  0%, 12%, 100%{
    transform: translateY(0);
  }
  17%, 29%{
    transform: translateY(-25%);
  }
  34%, 46%{
    transform: translateY(-50%);
  }
  51%, 63%, 90%{
    transform: translateY(-75%);
  }
}


*:focus {
  outline: none;
}

.specFont{
  font-family: 'Zen Dots', sans-serif;
}
.opacity-54{
  opacity: 0.54;
}

.ratio-1\/1 {
  width: 100%;
  padding-top: 100%; /*  Aspect Ratio */
  position: relative;
}

.ratio-16\/9 {
  width: 100%;
  padding-top: 56.25%; /*  Aspect Ratio */
  position: relative;
}

.ratio-in {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.wholebg {
  position: relative;
}
.wholebg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-repeat: repeat;
  background-size: 100% auto;
  z-index :-1;
  /* background-position-y: 50%; */
  /* background-position-x: 100%; */
}

.logobg {
  position: relative;
}
.logobg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-repeat: repeat;
  background-size: contain;
  
  /* background-position-y: 50%; */
  /* background-position-x: 100%; */
}

.bg1 {
  position: relative;
}
.bg1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* background-position-y: 50%; */
  /* background-position-x: 100%; */
}


.bg2 {
  position: relative;
}
.bg2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* background-position-y: 50%; */
  /* background-position-x: 100%; */
}


.buttonStyle1{
  border-color: #C70E39;
  color:#C70E39;
}
.buttonStyle1:hover{
  background-color: #C70E39;
  color:white;
}
.buttonStyle1 .dot{
  border-color: #C70E39;
}
.buttonStyle1:hover .dot{
  border-color: white;
}

.buttonStyle2{
  border-color: #4F86CC;
  color:#4F86CC;
}
.buttonStyle2:hover{
  background-color: #4F86CC;
  color:white;
}
.buttonStyle2 .dot{
  border-color: #4F86CC;
}
.buttonStyle2:hover .dot{
  border-color: white;
}

.buttonStyle3{
  border-color: #FD9E0E;
  color:#FD9E0E;
}
.buttonStyle3:hover{
  background-color: #FD9E0E;
  color:white;
}
.buttonStyle3 .dot{
  border-color: #FD9E0E;
}
.buttonStyle3:hover .dot{
  border-color: white;
}

.buttonStyle4{
  border-color: #62D569;
  color:#62D569;
}
.buttonStyle4:hover{
  background-color: #62D569;
  color:white;
}
.buttonStyle4 .dot{
  border-color: #62D569;
}
.buttonStyle4:hover .dot{
  border-color: white;
}

.buttonStyle5{
  border-color: #4F86CC;
  color:white;
}
.buttonStyle5:hover{
  background-color: #4F86CC;
  color:white;
}
.buttonStyle5 .dot{
  border-color: white;
}
.buttonStyle5:hover .dot{
  border-color: white;
}

.buttonStyle6{
  border-color: #FD9E0E;
  color:white;
}
.buttonStyle6:hover{
  background-color: #FD9E0E;
  color:white;
}
.buttonStyle6 .dot{
  border-color: white;
}
.buttonStyle6:hover .dot{
  border-color: white;
}





.appWidth{
  width: 100%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}


.text-h1{
  font-size: 36px;
  line-height: 43px;
}
.text-h1\.5{
  font-size: 33px;
  line-height: 40px;
}
.text-h1\.75{
  font-size: 29px;
  line-height: 35px;
}
.text-h2{
  font-size: 26px;
  line-height: 31px;
}
.text-h2\.5{
  font-size: 26px;
  line-height: 31px;
}
.text-h3{
  font-size: 22px;
  line-height: 26px;
}
.text-h4{
  font-size: 18px;
  line-height: 24px;
}
.text-h5{
  font-size: 16px;
  line-height: 19px;
}

@media screen and (min-width: 991px) {
  
.text-h1{
  font-size: 56px;
  line-height: 68px;
}
.text-h1\.5{
  font-size: 44px;
  line-height: 53px;
}
.text-h1\.75{
  font-size: 38px;
  line-height: 46px;
}
.text-h2{
  font-size: 32px;
  line-height: 39px;
}

}

fieldset{
  border:0px!important;
}

.gradiBtn{
  background: linear-gradient(225deg, rgba(241,148,108,1) 0%, #FD612A 100%);
  border-radius: 12px;
  color: #FFFFFF;
  font-weight: 400;
}

.whiteBtn{
  background: #FFFFFF;
  border-radius: 9999px;
  font-weight: 900;
}
.swiper{
  max-width: 100vw;
  overflow: visible!important;
}
.swiper-pagination{
  text-align: start!important;
  padding-left: 10px;
  padding-right: 10px;
   bottom: -50px!important;
   
}

.swiper-pagination-bullet{
  width: 12px!important;
  height: 12px!important;
  background-color: transparent!important;
  border: 3px solid rgba(255, 255, 255, 0.35)!important;
}

.swiper-pagination-bullet-active{
  background-color: #C70E39!important;
  border: 0px solid rgba(255, 255, 255, 0.35)!important;
}

.service .swiper-pagination-bullet{
  width: 12px!important;
  height: 12px!important;
  background-color: transparent!important;
  border: 3px solid #ADB1B2!important;
}

.service .swiper-pagination-bullet-active{
  background-color: #FD9E0E!important;
  border: 0px solid #ADB1B2!important;
}

.service2 .swiper-pagination-bullet{
  width: 12px!important;
  height: 12px!important;
  background-color: transparent!important;
  border: 3px solid #ADB1B2!important;
}

.service2 .swiper-pagination-bullet-active{
  background-color: #4F86CC!important;
  border: 0px solid #ADB1B2!important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.animTitle {
  background-image: linear-gradient(
    to right,
    #000,
    #000 50%,
    #1ac768 50%
  );
  background-size: 200% 100%;
  background-position: 0%;
  display: inline-block;
  padding: 5px 0;
  position: relative;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
}

.animTitle:before{
  content: '';
  background: #1ac768;
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 3px;
  transition: all 0.3s ease-in-out;
}

.animTitle:hover ,.selTitle{
 background-position: -100%;
}

.animTitle:hover::before ,.selTitle:before{
  width: 100%;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}