
  

  .loading-spinner {
    border: 4px solid rgba(255, 255, 255, 0.15); /* 边框样式 */
    border-top: 4px solid #94CB3C; /* 顶部边框颜色 */
    border-radius: 50%; /* 边框圆角，使其看起来像个圆 */
    width: 40px; /* 宽度 */
    height: 40px; /* 高度 */
    animation: spin 1s linear infinite; /* 旋转动画，持续时间为1秒，线性无限循环 */
  }
  

  @keyframes spin {
    0% { transform: rotate(0deg); } /* 初始位置：不旋转 */
    100% { transform: rotate(360deg); } /* 结束位置：旋转360度，形成旋转动画 */
  }
  